<template>
  <div class="relative">
    <ContentLoader v-if="loading.task" type="row" />
    <div v-if="!loading.task && task?.key" class="pb-5">
      <fw-heading size="h3" muted>{{ task.id.toUpperCase() }}</fw-heading>
      <fw-heading size="h1">{{ task.title }}</fw-heading>
      <div class="text-gray-500 font-semibold my-1">{{ task.created_at | humanDateTimePT }}</div>

      <div class="mt-3 mb-5 border-b pb-5">
        <fw-tag :type="statusTagColor[task.status]" size="lg">{{ $t(`status.${task.status}`) }}</fw-tag>
      </div>

      <fw-panel-box class="px-2 py-4 my-3" :inner-class="task.ratings?.length ? 'flex gap-5' : null">
        <div v-if="!task.ratings?.length && task.status == 'closed'" class="flex flex-col gap-3">
          <div class="flex flex-col items-center justify-center gap-2">
            <div>
              <fw-icon-checkbox-circle class="text-gray-400 w-12 h-12" />
            </div>
            <div>
              <fw-heading size="h3">
                O seu pedido foi fechado.
              </fw-heading>
            </div>
          </div>
          <div class="flex flex-col justify-center gap-3 items-center mb-2">
            <div class="text-sm text-gray-600 font-medium text-center max-w-lg mx-auto">
              Esperamos ter ajudado e gostariamos muito de receber a sua opinião. Para isso, por favor, classifique a
              sua experiência com este pedido de ajuda.
            </div>
            <div v-if="!viewingWithToken" class="flex flex-col gap-1 items-center mt-3 mb-2">
              <b-rate :spaced="true" :icon-pack="'fa'" size="is-large" @change="$emit('set-rating', $event)" />
            </div>
            <div v-else class="text-sm text-gray-500 mb-3">
              Para o fazer, por favor, autentique-se na plataforma.
            </div>
          </div>
        </div>
        <div
          class="flex flex-col gap-2 flex-1"
          :class="{ 'justify-center items-center': task.status == 'closed' && !task.ratings?.length }"
        >
          <div>
            <fw-label marginless>Equipa responsável pelo seu pedido</fw-label>
          </div>
          <div>
            <fw-heading size="lg">{{ space.team_name || space.title }}</fw-heading>
          </div>
          <div v-if="queue?.title">
            <fw-tag type="light-border-box" size="sm">
              Fila <span class="ml-1 font-mono">{{ queue.prefix }}</span>
            </fw-tag>
          </div>
        </div>
        <div
          v-if="
            task.ratings?.length &&
              (loggedUser.key === task.creator_key || loggedUser.key === task.user_key || isDebugMode)
          "
          class="border-l pl-5 border-gray-300 w-3/5"
        >
          <fw-label>{{ $t('rating') }}</fw-label>
          <div v-if="task.ratings?.length">
            <div v-for="rating in task.ratings" :key="rating.key">
              <b-rate size="is-medium" :show-score="true" :value="rating.score" :spaced="true" :disabled="true" />
              <div v-if="rating.message" class="text-sm text-gray-500 font-serif -mt-1">"{{ rating.message }}"</div>
              <div class="text-xs text-gray-500 mt-0.5">{{ rating.created_at | humanDateTimePT }}</div>
            </div>
          </div>
        </div>
      </fw-panel-box>

      <fw-panel-box v-if="task.action_token" type="orange" class="px-2 py-3 my-3 font-medium">
        <div class="flex gap-3">
          <div>
            <fw-icon-eye class="w-8 h-8 flex-shrink-0 opacity-80" />
          </div>
          <div class="flex flex-col gap-1">
            <div v-if="viewingWithToken" class="text-sm">
              Está a visualizar este pedido através de um código de acesso registado no endereço desta página. Por
              favor, partilhe apenas este endereço com quem pretender que tenha conhecimento das informações existentes
              neste pedido.
            </div>
            <div v-else-if="$store.getters.isLoggedIn && task.action_token" class="text-sm flex flex-col gap-3">
              <div>
                Para facilitar o acompanhamento deste pedido por outros utilizadores, não autenticados, foi gerado um
                código de acesso único. Este código permite a visualização do estado e detalhes do pedido, mas não
                permite a interação com o mesmo.
              </div>
              <div class="flex gap-3 flex-1 items-center bg-white bg-opacity-50 rounded-lg">
                <div class="flex-1 relative">
                  <input type="text" :value="taskPublicUrlWithToken" class="px-3 py-1.5 bg-transparent w-full" />
                  <div class="w-12 h-full bg-gradient-to-l from-[#FAF7F2] to-transparent absolute top-0 right-0"></div>
                </div>
                <div>
                  <fw-button
                    :icon="urlCopiedToClipboard ? 'check' : 'copy'"
                    :type="'link'"
                    @click.native="toClipboardUrlWithToken"
                  >
                    Copiar</fw-button
                  >
                </div>
              </div>
            </div>
            <div class="flex gap-3 justify-between items-center">
              <div class="text-sm text-gray-500">
                O código de acesso irá expirar em {{ task.token_expires_at | humanDateTimePT }}
              </div>
              <div>
                <fw-button v-if="$store.getters.isLoggedIn" type="link" @click.native="$emit('reset-token')"
                  >Gerar novo código</fw-button
                >
              </div>
            </div>
          </div>
        </div>
      </fw-panel-box>

      <fw-panel title="Detalhes do pedido" title-size="h4" boxed="sm" class="my-5" custom-class="flex flex-col gap-3">
        <div v-if="task.type && task.type.text">
          <fw-label>{{ $t('subject') }}</fw-label>
          <div>{{ task.type.text }}</div>
        </div>

        <slot name="context"></slot>

        <div>
          <fw-label>{{ $t('description') }}</fw-label>
          <div class="font-normal html-content" v-html="task.description"></div>
        </div>

        <div>
          <fw-label>{{ $t('files') }}</fw-label>

          <div v-if="task.files && task.files.length > 0">
            <RecordFileEntry
              v-for="file in task.files"
              :key="file.key"
              paddingless
              class="mb-2"
              :can-edit="false"
              :allow-classified="false"
              :file="file"
              :can-remove="false"
              @download="downloadFile(file)"
            />
          </div>
          <fw-panel-info v-else type="basic" clean>{{ $t('noFiles') }}.</fw-panel-info>
        </div>

        <div>
          <fw-label>{{ $t('createdAt') }}</fw-label>
          <div>{{ task.created_at | formatDateTime }}</div>
        </div>

        <div v-if="task.close_at">
          <fw-label>{{ $t('closedAt') }}</fw-label>
          <div>{{ task.close_at | formatDateTime }}</div>
        </div>
      </fw-panel>

      <fw-panel :title="$t('messages')" title-size="h4">
        <PanelMessages
          :users="users"
          :messages="messages"
          :pagination="pagination"
          :loading="loading.messages"
          :show-public-private-toggle="false"
          :force-hide-private-messages="true"
          :can-add-message="$store.getters.isLoggedIn"
          :default-add-message-is-private="false"
          @page-changed="$emit('page-changed', $event)"
          @add-message="$emit('add-message', $event)"
        />
      </fw-panel>
    </div>
  </div>
</template>

<script>
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'

export default {
  components: {
    ContentLoader,
    PanelMessages,
    RecordFileEntry,
  },

  props: {
    loading: {
      type: Object,
      default: () => {
        return {
          messages: false,
          task: false,
        }
      },
    },
    task: {
      type: Object,
      default: () => {},
    },
    space: {
      type: Object,
      default: () => {},
    },
    queue: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 25,
        }
      },
    },
    users: {
      type: Object,
      default: () => {},
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      statusTagColor: {
        new: 'primary',
        open: 'light-orange',
        closed: 'light-gray',
      },
      urlCopiedToClipboard: false,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    viewingWithToken() {
      return !!(
        !this.$store.getters.isLoggedIn &&
        this.$router.currentRoute.name === 'tasks-support-task-no-auth' &&
        this.$route.params?.token
      )
    },
    taskPublicUrlWithToken() {
      if (!this.task.action_token) return null
      return `${
        process.env.VUE_APP_IS_PRODUCTION
          ? 'https://uctasks.uc.pt'
          : process.env.VUE_APP_IS_STAGING
          ? 'https://uctasks.staging.ucframework.pt'
          : 'https://uctasks.dev.ucframework.pt'
      }/support/task/t/${this.task.action_token}`
    },
    isDebugMode() {
      // TODO: Change this to make sure backend does not send any feedback data
      // if user in not a space manager or admin (space or global)
      return Boolean(localStorage.getItem('fw-debug'))
    },
  },

  methods: {
    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    toClipboardUrlWithToken() {
      if (this.taskPublicUrlWithToken === null) return
      this.$copyText(this.taskPublicUrlWithToken).then(() => {
        this.urlCopiedToClipboard = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Link copiado para a área de transferência.`,
            position: 'is-top',
          })
        }
        this.clipboard_timer = setTimeout(() => {
          this.urlCopiedToClipboard = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "rating": "A sua classificação",
    "title": "Título",
    "description": "Mensagem",
    "messages": "Mensagens",
    "createdAt": "Criado em",
    "closedAt": "Fechado em",
    "subject": "Assunto",
    "files": "Ficheiros",
    "noFiles": "Não foram anexados ficheiros",
    "status": {
      "label": "Estado",
      "open": "Aberto",
      "closed": "Fechado"
    }
  },
  "en": {
    "rating": "Rating",
    "title": "Title",
    "description": "Description",
    "messages": "Messages",
    "createdAt": "Criado em",
    "closedAt": "Fechado em",
    "subject": "Subject",
    "files": "Files",
    "noFiles": "No files uploaded",
    "status": {
      "label": "Status",
      "open": "Open",
      "closed": "Closed"
    }
  }
}
</i18n>
